import i18n from '../util/localization'

const getMatchingPropForVehicleName = (vehicleIdentifier: string = '') => {
  switch (vehicleIdentifier) {
    case 'Registration number': return 'registrationNumber'
    case 'Chassi number': return 'chassisNumber'
    case 'Vehicle alias': return 'alias'
    default: return 'chassisNumber'
  }
}

export const transformDataForEnvironmentalReport = (
  results,
  propulsionType: PropulsionType,
  vehicleIdentifierFromStore: string
) : Report => {
  if (ENV_VAR_APPLICATION === 'ENVIRONMENTAL') {
    if (results === null || results === undefined) {
      return { data: { equipments: null, units: null }, info: { periodStart: null, periodUnit: null, propulsionSystem: null } }
    }

    const vehicleIdentifier = getMatchingPropForVehicleName(vehicleIdentifierFromStore)

    const tempBuiltObject = {
      data: {
        equipments: [],
        summary: null,
        units: {
          ...results.units,
          fuelConsumption: propulsionType === 'gas' ? results.units?.totalGas : results.units?.totalFuel,
          drivenDistance: results.units?.distance,
          cO2: 'kg',
          co: 'kg',
          nox: 'kg',
          pm: 'kg',
          hc: 'kg'
        }
      }
    }

    const allVehicles: any[] = results.emissionRecords
    let filteredVehicles: any[]

    switch (propulsionType) {
      case 'liquid': {
        filteredVehicles = allVehicles.filter(({ equipment }) => (equipment.propulsionSystem?.toLowerCase() === 'combustionengine' && (equipment.fuelType?.toLowerCase() === 'diesel' || equipment.fuelType?.toLowerCase() === 'ethanol') && equipment.equipmentType?.toLowerCase() !== 'engine'))
        break
      }
      case 'gas': {
        filteredVehicles = allVehicles.filter(({ equipment }) => (equipment.propulsionSystem?.toLowerCase() === 'combustionengine' && equipment.fuelType?.toLowerCase() === 'gas'))
        break
      }
      case 'engines': {
        filteredVehicles = allVehicles.filter(({ equipment }) => (equipment.propulsionSystem?.toLowerCase() === 'combustionengine' && (equipment.fuelType?.toLowerCase() === 'diesel' || equipment.fuelType?.toLowerCase() === 'ethanol') && equipment.equipmentType?.toLowerCase() === 'engine'))
        break
      }
      case 'electric': {
        filteredVehicles = allVehicles.filter(({ equipment }) => equipment.propulsionSystem?.toLowerCase() === 'electricmotor')
        break
      }
      case 'hybrid': {
        filteredVehicles = allVehicles.filter(({ equipment }) => equipment.propulsionSystem?.toLowerCase() === 'hybrid')
        break
      }
      default: break
    }

    if (filteredVehicles?.length) {
      const nullTrends = {
        previous: null,
        delta: null,
        isGood: null
      }

      tempBuiltObject.data.equipments = filteredVehicles.map(singleRecord => {
        const tempInfo = {
          externalEquipmentReference: singleRecord.equipment.externalEquipmentReference,
          name: singleRecord.equipment[vehicleIdentifier] || singleRecord.equipment.chassisNumber || i18n.t('Okänd'),
          chassisNumber: singleRecord.equipment.chassisNumber,
          periodStart: null,
          periodStop: null,
          emissionSpecDetails: singleRecord.details
        }

        const tempParameters = {
          drivenDistance: {
            value: singleRecord.basicOperationalData.distance?.toFixed(1) || 0,
            ...nullTrends
          },
          drivenDistanceOnBattery: {
            value: singleRecord.basicOperationalData.drivenDistanceOnBattery?.toFixed(1) || '-',
            ...nullTrends
          },
          fuelConsumption: {
            value: propulsionType === 'gas'
              ? (singleRecord.basicOperationalData.totalGas || 0)
              : (singleRecord.basicOperationalData.totalFuel || 0),
            ...nullTrends
          },
          totalFuel: {
            value: singleRecord.basicOperationalData.totalFuel?.toFixed(1) || '-',
            ...nullTrends
          },
          energyConsumption: {
            value: singleRecord.basicOperationalData.energyConsumption?.toFixed(1) || '-',
            ...nullTrends
          },
          cO2: {
            value: (singleRecord.calculatedEmission?.cO2 / 1000 || 0).toFixed(1),
            ...nullTrends
          },
          co: {
            value: (singleRecord.calculatedEmission?.co / 1000 || 0).toFixed(1),
            ...nullTrends
          },
          hc: {
            value: (singleRecord.calculatedEmission?.hc / 1000 || 0).toFixed(1),
            ...nullTrends
          },
          nox: {
            value: (singleRecord.calculatedEmission?.nox / 1000 || 0).toFixed(1),
            ...nullTrends
          },
          pm: {
            value: (singleRecord.calculatedEmission?.pm / 1000 || 0).toFixed(1),
            ...nullTrends
          },
          totalReductantUsed: {
            value: singleRecord.basicOperationalData.totalReductantUsed?.toFixed(1) || '-',
            ...nullTrends
          },
          totalRuntime: {
            value: singleRecord.basicOperationalData.totalRuntime,
            ...nullTrends
          },
          specification: {
            value: singleRecord.specification?.displayName || '',
            ...nullTrends
          }
        }

        return {
          info: tempInfo,
          parameters: tempParameters
        }
      })
    }

    return tempBuiltObject
  }

  return results
}
