import React from 'react'
import { Popover, OverlayTrigger, Carousel } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle as infoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle'
import moment from 'moment'

import i18n from '../../util/localization'
import GAEvent from '../GAEvent'
import Style from './EmissionSpecDetails.scss'

import { useIsFeatureEnabled } from '../FeatureToggle/hooks'

interface EmissionSpecDetailsProps {
  details: Array<any>,
  ReactGA: any
}

type SpecDetail = {
    displayName: string,
    description: string,
    nox: number,
    pm: number,
    hc: number,
    co: number,
    cO2: number
}

const oldBaseUrl = ENV_VAR_MY_SCANIA_VP_BASE_URL
const oldEmissionSpecSettingsUrl = ENV_VAR_EMISSION_SPEC_SETTINGS_MY_SCANIA

const newVPBaseUrl = ENV_VAR_MY_SCANIA_NEW_VP_BASE_URL
const newVPEmissionSpecSettingsUrl = ENV_VAR_EMISSION_SPEC_PAGE_NEW_VP

const reportEvent = (ReactGA: any) => {
  ReactGA.event({
    category: 'click',
    action: 'carousel switch in info popup for emission multi spec'
  })
}

const formatSpecDetailsDate = (start: string, end: string): string => `${moment(start).format('YYYY/MM/DD')} - ${moment(end).format('YYYY/MM/DD')}`

const singleSpec = (entry: any, newVPtoggleEnabled: boolean) => (
  entry.specification && <>
      <h4>{entry.specification.displayName}</h4>
      <div className={Style.subheader}>{entry.specification.description}</div>
      <div className={Style.specDetailsContainer}>
          <div className={Style.individualDetail}>
              <strong>{i18n.t('NOx')}</strong> <br />
              {entry.specification.nox}
          </div>
          <div className={Style.individualDetail}>
              <strong>{i18n.t('PM')}</strong> <br />
              {entry.specification.pm}
          </div>
          <div className={Style.individualDetail}>
              <strong>{i18n.t('HC')}</strong> <br />
              {entry.specification.hc}
          </div>
          <div className={Style.individualDetail}>
              <strong>{i18n.t('CO')}</strong> <br />
              {entry.specification.co}
          </div>
          <div className={Style.individualDetail}>
              <strong>{i18n.t('TH_CO2Total')}</strong> <br />
              {entry.specification.cO2}
          </div>
          <div className={Style.individualDetail}>
              <strong>{i18n.t('Intervall')}</strong> <br />
              {formatSpecDetailsDate(entry.basicOperationalData.timeStart, entry.basicOperationalData.timeStop)}
          </div>
      </div>
      <div className={Style.changeButton}>
        <a
          title={i18n.t('Ändra')}
          target='_blank'
          href={`${newVPtoggleEnabled ? newVPBaseUrl : oldBaseUrl}${newVPtoggleEnabled ? newVPEmissionSpecSettingsUrl : oldEmissionSpecSettingsUrl}`}
          rel="noreferrer"
        >
          <button className='btn btn-primary'>{i18n.t('Ändra')}</button>
        </a>
      </div>
  </>
)

const popover = (allDetails: Array<any>, ReactGA: any, newVpToggleEnabled: boolean) => {
  return (
        <Popover id='spec-popover' className={`scania-popover ${Style.popoverContainer} hidden-sm hidden-xs`}>
            <GAEvent category="click" action="user clicked emissions specs details popover" ReactGA={ReactGA} />
            {
            allDetails && allDetails.length > 1
              ? <Carousel indicators interval={null} controls onSelect={reportEvent(ReactGA)}>
                  {
                      allDetails.map(item => (
                        <Carousel.Item key={item.specification.id}>
                          <div className={Style['carousel-item']}>{singleSpec(item || null, newVpToggleEnabled)}</div>
                        </Carousel.Item>
                      ))
                  }
                  </Carousel>
              : singleSpec(allDetails[0] || null, newVpToggleEnabled)
            }
        </Popover>
  )
}

const EmissionSpecDetails = (props: EmissionSpecDetailsProps) => {
  const newVPtoggleEnabled = useIsFeatureEnabled('VPStandaloneApp')

  if (props.details?.[0]?.specification) {
    return (
      <div className={`col-sm-12 hidden-xs hidden-sm ${Style.specModalIcon}`}>
          <OverlayTrigger trigger="click" placement="bottom" overlay={popover(props.details, props.ReactGA, newVPtoggleEnabled)}>
              <FontAwesomeIcon
                  className="fa-lg pull-right mt-3 mr-3"
                  style={{ cursor: 'pointer', color: '#4A8BA4' }}
                  icon={infoCircle}
              />
          </OverlayTrigger>
      </div>
    )
  }
  return null
}

export default EmissionSpecDetails
